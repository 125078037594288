/**
 * Retrieves LaunchDarkly context based on headers and default locale.
 * @param headers HTTP headers containing context information.
 * @param defaultLocale The default locale to use if not specified in headers.
 * @returns LaunchDarkly context object.
 * @category Utils
 */
export const getLDContext = (headers, defaultLocale) => {
  const { brand, isNetlify, targetEnv, disableProxyForNetlify } = useRuntimeConfig().public
  const { locale, 'x-ld-passthrough': ldPassthroughHeader = '' } = headers

  return {
    key: brand,
    custom: {
      brand,
      disableProxyForNetlify,
      ldPassthroughHeader: ldPassthroughHeader || '',
      locale: locale || defaultLocale,
      targetEnv,
      isNetlify
    }
  }
}
