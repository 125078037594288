import { stringifyQuery } from 'ufo'
import { partytownSnippet } from '@builder.io/partytown/integration'
import type { GTMEvent } from '#types/gtm'
import { type GTMPush, events } from '#commerce/gtm'

declare global {
  interface Window {
    dataLayer: GTMEvent[]
  }
}

export interface GtmPlugin {
  push: GTMPush
  pushEventObj: (eventObj: GTMEvent) => void
  disableAppEvents?: string[]
}

declare module '#app' {
  interface NuxtApp {
    $gtm: GtmPlugin
  }
}

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $gtm: GtmPlugin
  }
}

function loadGTMScript(id: string, innerHTML: string, partytown: boolean) {
  if (partytown) {
    const el = document.querySelector(`script[id="${id}"]`)

    if (!el) {
      const script = document.createElement('script')
      script.setAttribute('id', id)
      script.type = 'text/partytown'

      // We add a dataLayer push proxy, so we can emit events into the main thread
      script.innerHTML = `
        window.dataLayer = new Proxy([], {
          set: (obj, prop, value) => {
            if (prop !== 'length') {
              setTimeout(() => {
                // adds timeout so gtm can take time to process and adds an gtm.uniqueId
                window.postMessage({ type: 'gtm', item: value });
              }, 500);
            }
            return Reflect.set(obj, prop, value);
          }
        });
        ${innerHTML}
      `
      document.head.appendChild(script)

      /**
       * We need to add the script in an imperative way because it's required to dispatch this event in order to partytown to load it.
       * By using useHead it doesn't always work the way it should work, sometimes it loads and sometime it doesn't because useHead is async
       */
      window.dispatchEvent(new CustomEvent('ptupdate'))
    }

    // Using this method we expose the gtm layer into the main thread
    window.onmessage = (ev) => {
      if (ev.data.type === 'gtm') {
        // I can't call push because it's a proxy forwarder for partytown
        const partytownPush = window.dataLayer.push
        window.dataLayer[window.dataLayer.length] = ev.data.item
        window.dataLayer.push = partytownPush
      }
    }
    return
  }

  useHead({
    script: [
      {
        id,
        innerHTML,
        type: 'text/javascript'
      }
    ]
  }, { mode: 'client' })
}

export default defineNuxtPlugin<{ gtm: GtmPlugin }>(() => {
  const { gtm } = useRuntimeConfig().public.features
  const { configGtm } = useFeatureFlags()
  const locale = useLocale()

  const partytownActive = configGtm.partytown || window.location.search.includes('partytown_debug')

  if (!gtm.enabled) {
    return {
      provide: {
        gtm: {
          push: () => null,
          pushEventObj: () => null
        }
      }
    }
  }

  if (!configGtm) {
    const notConfig = (args) => console.log('%cGTM NOT CONFIGURED', 'background: coral;border-radius: 0.5em;color: white;font-weight: bold;padding: 2px 0.5em;', args)
    return {
      provide: {
        gtm: {
          push: (...args) => notConfig(args),
          pushEventObj: notConfig
        }
      }
    }
  }

  const log = (...args: any[]) => {
    if (configGtm.debug)
      console.log('%cGTM', 'background: #2E495E;border-radius: 0.5em;color: white;font-weight: bold;padding: 2px 0.5em;', ...args)
  }

  const pushEventObj: GtmPlugin['pushEventObj'] = (eventObj) => {
    if (!window.dataLayer)
      window.dataLayer = []

    window.dataLayer.push(eventObj)
    log('push', eventObj)
  }

  const push: GtmPlugin['push'] = (event, ...payload) => {
    const [namespace, generator] = event.split('.')
    const eventObj = events[namespace][generator](...payload)

    if (eventObj)
      pushEventObj(eventObj)
  }

  const params = {
    gtm_auth: configGtm.gtm_auth || undefined,
    gtm_preview: configGtm.gtm_preview || undefined,
    gtm_cookies_win: configGtm.gtm_cookies_win || undefined
  }

  const stringifiedParams = stringifyQuery(params)

  const isUsingTagManagerAssistant = window?.location?.search.includes('gtm_debug')

  const gtmScript = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl+ '${stringifiedParams.length > 0 ? `&${stringifiedParams}` : ''}';f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer', '${configGtm.id}');
  `

  const { brand } = useRuntimeConfig().public

  const usePartytown = !isUsingTagManagerAssistant && partytownActive

  // partytown config
  if (usePartytown) {
    const currentLocation = window.location.host

    useHead({
      script: [
        {
          id: 'config',
          // function calls on the main thread to forward to web worker

          innerHTML: `partytown =
          { forward: ['dataLayer.push'],
          debug: ${window.location.search.includes('partytown_debug')},
          resolveUrl: (url, location, type) => {
            if (type === 'script' && url.href.indexOf('${currentLocation}') < 0) {
              return '/fapi/proxyScript?url=' + encodeURIComponent(url.href);
            }
            return url;
          },
          };`,
          type: 'text/javascript'
        },
        {
          id: 'partytown',
          innerHTML: partytownSnippet(),
          type: 'text/javascript'
        }
      ]
    })
  }

  const thirdPartyScripts = [
    configGtm.appmeasurement_id ? loadScript(`https://static.${brand}.com/an/production/${configGtm.appmeasurement_id}/appMeasurement.js`) : null
  ]

  /**
   * @description
   * https://digital.vfc.com/jira/browse/GLOBAL15-52262
   * OneTrust script doesn't trigger onload and onerror events using vue/usehead when developer console tab is open, blocking gtm
   */
  Promise.all(thirdPartyScripts)
    .finally(() => loadGTMScript('gtmscript', gtmScript, usePartytown))

  return {
    provide: {
      gtm: {
        push,
        pushEventObj,
        disableAppEvents: [
          `c-slug_${locale}`,
          `cart_${locale}`,
          `checkout_${locale}`,
          `index_${locale}`,
          `order-confirmation_${locale}`,
          `p-slug_${locale}`,
          `search_${locale}`,
          `slug_${locale}`
        ]
      }
    }
  }
})
