<template>
  <modal-employee-terms lazy />
  <modal-forgot-password />
  <modal-interests lazy />
  <modal-welcome />
  <modal-klarna lazy />
  <modal-location-confirmation hide-close-button />
  <modal-loyalty-sign-up />
  <panel-mini-cart size="lg" />
  <modal-sign-in />
  <modal-sign-up />
  <modal-three-ds-challenge />
</template>

<script lang="ts" setup>
const {
  ModalEmployeeTerms,
  ModalForgotPassword,
  ModalInterests,
  ModalKlarna,
  ModalLocationConfirmation,
  ModalLoyaltySignUp,
  PanelMiniCart,
  ModalSignIn,
  ModalSignUp,
  ModalThreeDsChallenge,
  ModalWelcome
} = useDialogsStore()
</script>
