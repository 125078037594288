export default defineNuxtPlugin({
  name: 'content-preview',
  setup: () => {
    const { configApiDomains: { cmsBaseUrl }, enableFContentProxy } = useFeatureFlags()
    const { cmsApiRoutePrefix, isDev } = useRuntimeConfig().public
    const { isPreview } = useCms()
    const metadata = ref<string>()
    const script = ref()

    useHead(() => ({
      bodyAttrs: {
        'data-cm-metadata': metadata.value
      },
      script
    }))

    watchOnce(() => isPreview, async (value) => {
      if (!value) return

      const { sliderMetadata } = await import('#content/config/preview')
      const url = isDev || enableFContentProxy ? cmsApiRoutePrefix : cmsBaseUrl

      metadata.value = JSON.stringify(sliderMetadata)
      script.value = [{
        key: 'cms-preview',
        src: `${url}/preview/static/coremedia.preview.js`
      }]
    }, { immediate: true })
  }
})
