import type { RouteLocationNormalizedLoaded } from 'vue-router'
import type { PageCategoryType, PageContext } from '#types/gtm'

/**
 * Each key in the pageMap object represents a unique page identifier.
 * The value associated with each key is an array which represents the pageCategory and pageName.
 * Example: 'account-order-history': ['My Account', 'Order History']
 * Result: { pageCategory: 'My Account', pageName: 'My Account: Order History' }
 */
const pageMap = {
  'checkout': ['Checkout'],
  'gift-card-balance': ['Gift Card', 'Check Balance'],
  'gift-card': ['Gift Card', 'Main Page'],
  'account-profile': ['My Account', 'Profile'],
  'account-address': ['My Account', 'Address Book'],
  'account-order-history': ['My Account', 'Order History'],
  'favorites': ['My Account', 'Favorites'],
  'account': ['My Account', 'Account'],
  'sign-in': ['My Account', 'Sign In'],
  'forgot-password': ['My Account', 'Forgot Password'],
  'order-history': ['Support', 'Order History'],
  'store-locator': ['Store Pages', 'Store Locator'],
  'cart': ['Cart'],
  'order-confirmation': ['Order Confirmation'],
  'order-status': ['Order Status']
}

const getPageInfo = ({ name, path }, pageData: PageContext): [category?: PageCategoryType, ...payload: any[]] => {
  const locale = useLocale()

  // Use pageCategory and pageName directly as provided from PageContext
  // example: 404 errors will not have a valid path to match against
  if (pageData.pageCategory && pageData.pageName)
    return [pageData.pageCategory, pageData.pageName]

  // otherwise, map them to strict types
  const routeName = name.replace(`_${locale}`, '')
  const info = pageMap[routeName]
  if (info) {
    const [pageCategory, pageName] = info
    return [pageCategory, pageName || pageCategory]
  }

  return {
    'c-slug': ['PLP', getPageBreadcrumb(pageData.breadcrumbs)?.join(' - ')],
    'p-slug': ['PDP', getUrlId(path, 'Product')],
    'search': ['Search', pageData.searchType]
  }[routeName] || []
}

export default {
  onLoadPageData: (route: RouteLocationNormalizedLoaded, pageContext: PageContext = {}) => {
    const { currency } = useRuntimeConfig().public
    const locale = useLocale()
    const [pageCategory, pageName] = getPageInfo(route, pageContext)
    const { suggestedPhrase } = history.state

    return {
      event: 'loadPageData',
      page: {
        addlBreadcrumbInfo: pageContext.breadcrumbs?.map(({ label, url }) => ({
          label,
          url,
          id: url?.split('-').pop()
        })),
        brand: useSiteName(),
        breadcrumb: getPageBreadcrumb(pageContext.breadcrumbs)?.join('|').toLowerCase(),
        categoryID: pageContext.categoryId,
        countryCode: useCountryCode(),
        countryLanguage: useLanguageCode().toLocaleLowerCase(),
        currencyCode: currency[locale],
        ...(pageCategory && { pageCategory }),
        ...((pageCategory && pageName) && {
          pageName: pageCategory === pageName
            ? pageName
            : `${pageCategory}: ${pageName}`
        }),
        pageNameEng: pageContext.pageNameEng || pageName,
        pageTitle: pageContext.pageTitle || document?.title,
        searchResults: pageContext.searchResults,
        searchTerm: pageContext.searchTerm || suggestedPhrase,
        searchTermAdj: pageContext.searchTermAdj,
        searchType: suggestedPhrase ? 'click' : pageContext.searchType,
        sourceId: pageContext.sourceId,
      },
      _clear: true
    }
  },
  onRouterChange: ({ fullPath, name }: RouteLocationNormalizedLoaded) => {
    return {
      'event': 'routerChange',
      'content-name': fullPath,
      'content-view-name': name
    }
  }
}
