interface crl8Script {
  createExperience: (id: string) => Promise<void>
  destroyExperience: (id: string) => Promise<void>
}

declare global {
  interface Window {
    crl8: crl8Script
  }
}

interface Crl8Plugin {
  create: (id: string) => Promise<void> | void
  destroy: (id: string) => Promise<void> | void
}

declare module '#app' {
  interface NuxtApp {
    $crl8: Crl8Plugin
  }
}

declare module 'vue' {
  interface NuxtApp {
    $crl8: Crl8Plugin
  }
}

export default defineNuxtPlugin({
  name: 'crl8',
  setup: () => {
    const { crl8SiteName } = useRuntimeConfig().public

    if (!crl8SiteName) {
      console.info('crl8 is not configured')

      return {
        provide: {
          crl8: {
            create: (_) => _,
            destroy: (_) => _
          }
        }
      }
    }

    const { $script } = useScript<crl8Script>({
      src: `https://edge.curalate.com/sites/${crl8SiteName}/site/latest/site.min.js`,
      async: true
    }, {
      trigger: 'manual',
      use() {
        return window.crl8
      }
    })

    /**
     * We need to keep track of to destroy promises because if we need to re-initialize the experience
     * on user routing, we need to wait for the previous experience to be destroyed before creating a new one.
     */
    const destroyQueue = new Map<string, Promise<void>>()

    const create = async (id: string) => {
      const crl8 = await $script.load()

      if (destroyQueue.has(id)) await destroyQueue.get(id)
      crl8.createExperience(id)
    }

    const destroy = async (id: string) => {
      // If the script is not loaded yet, we don't need to destroy the experience
      if ($script.status.value !== 'loaded') return

      const crl8 = await $script.load()

      const destroyPromise = crl8.destroyExperience(id)
      destroyQueue.set(id, destroyPromise)
      await destroyPromise
      destroyQueue.delete(id)
    }

    return {
      provide: {
        crl8: {
          create,
          destroy
        }
      }
    }
  }
})
