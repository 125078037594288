import { languageConfig } from '../../config/integrations/livechat'
import { initializeLiveChat } from '#core/integrations/live-chat'

export default defineNuxtPlugin(() => {
  const brandConfig = {
    brand: 'TBL',
    languageConfig,
    customStyleLocales: ['uk-en', 'de-de', 'it-it', 'fr-fr', 'es-es', 'nl-nl'],
    cssPath: '/livechat/css/LiveChatCodeCanvasTBL.css',
    logoUrl: 'https://vfeurope.lightning.force.com/servlet/servlet.ImageServer?id=0153X0000076sFv&oid=00Db0000000dbnM',
    avatarUrl: 'https://vfeurope.lightning.force.com/servlet/servlet.ImageServer?id=0153X000006McR0&oid=00Db0000000dbnM'
  }

  const { initLiveChat } = initializeLiveChat(brandConfig)

  initLiveChat()
})
