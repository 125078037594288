const consentGroups = {
  required: 'C0001',
  performanceAndAnalytics: 'C0002',
  functional: 'C0003',
  targeting: 'C0004',
}

const getCookie = (cName) => {
  const name = `${cName}=`
  const cArr = decodeURIComponent(document.cookie).split('; ')
  const cookie = cArr.find((val) => val.indexOf(name) === 0)
  return cookie?.substring(name.length) || undefined
}

const getMetaContent = (property: string) => document.querySelector(`meta[property="${property}"]`)?.getAttribute('content')

/**
 * Determines whether targeting is accepted based on the provided cookie and environment flags.
 * @param groups Flag options to be passed, i.e. isEmea and isPreview.
 * @param groups.isEmea Flag indicating if the user is in the EMEA region.
 * @param groups.isPreview Flag indicating if the user is in preview mode.
 * @returns A boolean indicating whether targeting is accepted.
 * @category Utils
 * @remarks Read more about oneTrust and targeting here https://digital.vfc.com/wiki/display/AN/OneTrust
 */
export const hasCookieConsent = (groups: (keyof typeof consentGroups)[]) => {
  const url = new URL(location.href)
  const isEmea = getMetaContent('region') === 'EMEA'
  const isPreview = url.searchParams.get('preview') === 'true'
  if (!isEmea || isPreview) return true

  const cookie = decodeURIComponent(getCookie('OptanonConsent') || '')
  if (!cookie) return false

  return groups.map((key) => consentGroups[key]).every((value) => cookie.includes(`${value}:1`))
}
