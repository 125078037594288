export default defineNuxtRouteMiddleware(async ({ path, query, hash }) => {
  if (path.startsWith('/fapi'))
    return

  const { locales, defaultLocale, urlPrefixStrategy } = useI18nConfig()
  const localeSlugs = locales.map(slug)

  const targetLocale = useLocale() || defaultLocale
  let targetSlug = slug(targetLocale)

  if (
    urlPrefixStrategy === 'no_prefix'
    || (urlPrefixStrategy === 'prefix_except_default' && targetLocale === defaultLocale)
  )
    targetSlug = ''

  const baseRoute = localeSlugs.reduce((p, s) => p.replace(s, ''), path).replace(/\/$/, '')
  const translatedRoute = useI18n().value.urls?.[baseRoute]

  const baseTargetRoute = translatedRoute || baseRoute
  const targetRoute = `${targetSlug}${translatedRoute || baseRoute}`

  if ((targetSlug && !path.startsWith(targetSlug)) || baseTargetRoute !== baseRoute) {
    return navigateTo({
      path: targetRoute,
      query,
      hash
    })
  }
})
